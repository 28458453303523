
#toolbar-title {
    font-family: Arial;
    color: #1d4c84;
    text-align: right;
    padding-top: 5px;

}

.toolbar-row {
    font-family: Arial;
    color: "#1d4c84";
    text-align: left;
    padding: 2px;
}

.toolbar-col {
    text-align: left;
}
